//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { searchApi } from "../api";

export default {
  name: "Search",
  data() {
    return {
      keyword: this.$route.params.keyword,
      resultList: [],
      resdata: "",
      total: 0,
      page: 1,
    };
  },
  created() {
    this.shous();
  },
  mounted() {
    window.addEventListener("scroll", this.lazyLoading); // 滚动到底部，再加载的处理事件
  },
  destroyed() {
    window.removeEventListener("scroll", this.lazyLoading);
  },
  methods: {
    async getResult() {
      this.resultList = [];
      this.page = 1;
      try {
        const res = await searchApi({
          keywords: this.keyword,
          page: this.page,
        });
        this.resultList = this.resultList.concat(res.data.media_res.data);
        this.total = res.data.media_res.total;
        this.resdata = res.data.media_res.data;
      } catch (e) {
        console.log(e);
      }
    },
    async shous() {
      try {
        const res = await searchApi({
          keywords: this.keyword,
          page: this.page,
        });
        this.resultList = this.resultList.concat(res.data.media_res.data);
        this.total = res.data.media_res.total;
        this.resdata = res.data.media_res.data;
      } catch (e) {
        console.log(e);
      }
    },
    // load() {
    //   this.page++;
    //   this.getResult();
    // },

    lazyLoading() {
      // 滚动到底部，再加载的处理事件
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        // 滚动到底部，逻辑代码
        //事件处理
        console.log("我已经滚动到底部了触发这个事件了"); //此处可以添加数据请求
        this.page++;
        this.shous();
      }
    },
    search() {
      this.page = 1;
      this.resultList = [];
      this.getResult();
    },
    toDetail(id) {
      this.$router.push({
        name: "detail",
        params: {
          id: id,
        },
      });
    },
  },
};
